<template v-if="organizationData">
  <b-card no-body class="mb-0">
    <div class="m-2">
      <b-row>
        <!-- Search -->
        <b-col cols="12" md="12">
          <div
            v-bind:class="{
              'align-items-center justify-content-end': isActive,
              'd-flex align-items-center justify-content-end': !isActive,
            }"
          >
            <b-input-group class="mr-1">
              <b-form-input
                v-model="searchQuery"
                @keyup.enter="searchWord()"
                :placeholder="$t('message.bookmark.search')"
              />
              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon"
                  @click="searchWord()"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <br v-if="isActive" />
            <!-- Filters -->
            <div>
              <div class="ecommerce-header-items">
                <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                >
                  <template slot="button-content">
                    <feather-icon icon="FilterIcon" />
                  </template>
                  <b-dropdown-item @click="getOrganizationList()">
                    {{ $t("message.Organization.all") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterData('Pendente')">
                    {{ $t("message.Organization.pending") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterData('Aprovado')">
                    {{ $t("message.Organization.approved") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterData('Rejeitado')">
                    {{ $t("message.Organization.reject") }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <!-- Filters -->

            <b-dropdown
              right
              variant="gradient-warning"
              class="mr-1"
              v-if="restrictions('button_export_data')"
            >
              <template slot="button-content">
                <feather-icon icon="DownloadIcon" />
              </template>
              <b-dropdown-item
                ><download-excel
                  :data="organizationList"
                  :before-generate="startDownload"
                  :before-finish="finishDownload"
                  :name="$t('message.buttons.dataInView')"
                >
                  {{ $t("message.buttons.dataInView") }}
                </download-excel></b-dropdown-item
              >
              <b-dropdown-item
                ><download-excel
                  :fetch="allData"
                  :before-generate="startDownload"
                  :before-finish="finishDownload"
                  :name="$t('message.buttons.allData')"
                >
                  {{ $t("message.buttons.allData") }}
                </download-excel></b-dropdown-item
              >
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="my-table"
      ref="reforganizationListTable"
      class="position-relative"
      :items="organizationList"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :busy="isBusy"
      show-empty
      @row-clicked="openViewPage"
      hover
    >
      <template #table-busy>
        <div class="text-center my-17">
          <b-spinner class="align-middle"></b-spinner>
          <strong> {{ $t("message.load") }}... </strong>
        </div>
      </template>

      <template slot="empty">
        <div class="text-center my-5">
          {{ $t("message.registerNotFound") }}
        </div>
      </template>

      <!-- Column: firstname -->
      <template #cell(firstName)="data">
        {{ data.item.responsiblePerson }}
      </template>

      <!-- Column: company -->
      <template #cell(company)="data">
        {{ data.item.company }}
      </template>

      <!-- Column: email -->
      <template #cell(email)="data">
        {{ data.item.emailCompany }}
      </template>
      <!-- Column: Status -->
      <template #cell(status)="data">
        {{ data.item.status }}
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >{{ $t("message.pagelength") }} {{ dataMeta.from }}
            {{ $t("message.of") }} {{ dataMeta.to }} {{ $t("message.of") }}
            {{ totalRegisters }} {{ $t("register") }}
          </span>
        </b-col>

        <b-col
          v-if="showPagination"
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRegisters"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
            @input="eventPage(currentPage)"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import Vue from "vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import RegisterOrganization from "@/views/pages/authentication/RegisterOrganization.vue";
import useorganizationList from "./useOrganizationList";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import store from "@/store";

Vue.component("downloadExcel", JsonExcel);

export default {
  components: {
    RegisterOrganization,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      organizationList: [],
      totalRegisters: 0,

      currentPage: 1,
      perPage: 0,
      from: true,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,
      organizationDataStatus: [],
      organizationListAll: [],
      searchQuery: "",

      //Select
      routeOptions: [],

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,
    };
  },

  computed: {
    tableColumns() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }

      return [
        { key: "firstName", sortable: false, label: this.getColLabel("name") },
        { key: "company", sortable: false, label: this.getColLabel("company") },
        { key: "email", sortable: false, label: this.getColLabel("email") },
        { key: "status", sortable: false, label: this.getColLabel("status") },
      ];
    },

    itemsFilter() {
      let valores = [];

      valores = valores.filter((item) => {
        if (this.selected == null) {
          return item;
        }

        return item.isActive === this.selected;

      });
    },
  },

  async created() {
    this.onResize();
    this.getOrganizationList();
  },

  mounted() {
    this.$root.$on("newListOrganization", (newListOrganization) => {
      this.organizationList = newListOrganization.content;
      this.totalRegisters = newListOrganization.totalElements;
      this.perPage = newListOrganization.numberOfElements;
      this.currentPage = 1;
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    async allData() {
      const response = await axios.get(
        `${URL_API}organization/pagelist?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
        { headers: getHeader(this.userData) }
      );
      return response.data.content;
    },

    restrictions(value) {
      return AccessControl(value);
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.isActive = ListWidth(this.windowWidth);
    },

    getColLabel(colName) {
      return this.$i18n.t("message.list." + colName);
    },

    openViewPage(row) {
      row._showDetails = !row._showDetails;
      this.$router.push({
        name: "apps-organization-view",
        params: { id: row.id },
      });
    },

    startDownload() {
      this.isBusy = true;
    },
    finishDownload() {
      this.isBusy = false;
    },

    allUsers(value) {
      if (value === false) {
        return "Não";
      } else {
        return "Sim";
      }
    },

    // methods of filter

    async filterData(filter) {
      this.organizationList = [];
      this.isBusy = true;

      await axios
        .get(`${URL_API}organization/filter/${filter}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.totalRegisters = response.data.length;
          this.organizationList = response.data;
          this.perPage = response.data.length;
          this.isBusy = false;
        });
    },

    async getOrganizationFilter() {
      this.isBusy = true;
      await axios
        .get(`${URL_API}organization/search?searchTerm=${this.searchQuery}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.organizationList = [];
          if (response.data != []) {
            for (var i = 0; i < response.data.length; i++) {
              this.organizationList.push(response.data[i]);
            }
          }
          this.isBusy = false;
          this.showPagination = false;
        });
    },

    async getOrganizationList() {
      this.organizationList = [];
      this.isBusy = true;
      await axios
        .get(
          `${URL_API}organization/pagelist?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.totalRegisters = response.data.totalElements;
          this.organizationList = response.data.content;
          this.perPage = response.data.numberOfElements;
          this.isBusy = false;
        });

      if (this.organizationList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
        this.currentPage = 1;
      }
    },

    async eventPage(currentPage) {
      this.isBusy = true;
      await axios
        .get(
          `${URL_API}organization/pagelist?page=${currentPage - 1}&sort=id,${
            this.paramData.listSort
          }&size=${this.paramData.tableSize}`,
          { headers: getHeader(this.userData) }
        )
        .then((response) => {
          this.organizationList = response.data.content;
          this.isBusy = false;
        });

      if (this.organizationList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }
    },

    async getOrganizationFilter() {
      this.isBusy = true;
      await axios
        .get(`${URL_API}organization/search?searchTerm=${this.searchQuery}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.organizationList = [];
          if (response.data != []) {
            for (var i = 0; i < response.data.length; i++) {
              this.organizationList.push(response.data[i]);
            }
          }
          this.isBusy = false;
          this.showPagination = false;
        });
    },

    searchWord() {
      if (this.searchQuery) {
        this.getOrganizationFilter();
      } else {
        this.getOrganizationList();
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },
  },

  setup() {
    const {
      perPage,
      currentPage,
      totalorganization,
      dataMeta,
      searchQuery,
      reforganizationListTable,
    } = useorganizationList();

    return {
      perPage,
      currentPage,
      totalorganization,
      dataMeta,
      searchQuery,
      reforganizationListTable,
    };
  },
};
</script>
